<template>
  <div class="error-main">
    <van-empty class="custom-image" :image="custom" description="抱歉您暂无预约号">
      <div class="btn">
        <van-button color="#106FFF" type="info" size="large" class="bottom-button" @click="$router.go(-1)">去预约</van-button>
      </div>
    </van-empty>
    <van-dialog v-model="warnShow" title="">
      <div class="dialog_img">
        <img src="../../assets/warning.png">
      </div>
      <div class="dialog_tit">
        您未关注公众号<br>请关注公众号，并回复”排号“！
      </div>
    </van-dialog>
    <van-dialog v-model="successShow" title="">
      <div class="dialog_img">
        <img src="../../assets/success.png">
      </div>
      <div class="success_tit">成功预约</div>
      <div class="success_info">请在固定时间去现场扫码取号</div>
    </van-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Button, Empty, Col, Row, Dialog } from 'vant'

export default {
  name: 'HomeIndex',
  components: {
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Col.name]: Col,
    [Row.name]: Row,
    'van-dialog': Dialog.Component
  },
  data() {
    return {
      custom: require('../../assets/error.png'),
      warnShow: false,
      successShow: true
    }
  },
  computed: {
    avatar() {
      return this.userInfo ? this.userInfo.avatar : this.logo
    },
    introduction() {
      return this.userInfo ? this.userInfo.introduction : '南京蓝牛软件有限公司'
    },
    ...mapGetters([
      'userInfo'
    ])
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
  .error-main{
    width: 100%;
    height: calc(100vh)
  }
  /deep/ .van-empty__bottom{width: calc(100vw)}
  /deep/ .van-empty__description{font-size: 18px;color: #8993A8}
  .btn{
    width: 100%;
    float: left;
    margin-top: calc(10vh);
    height: 50px;
  }
  .bottom-button {
    width: 90%;
    margin-left: 5%;
    height: 50px;
  }
  .custom-image{
    margin-top: calc(20vh);
    width: 100%;
    float: left;
  }
  .custom-image /deep/ .van-empty__image {
    width: 210px;
    height: 130px;
  }
  .dialog_img{width: 100%;height: 64px;text-align: center;line-height: 64px;margin: 25px 0;}
  .dialog_img img{height: 64px;}
  .dialog_tit{width: 100%;line-height: 1.5em;font-size: 16px;color: #8993A8;text-align: center;margin: 20px 0 35px 0;}
  /deep/ .van-dialog__footer{display: none}
  .success_tit{width: 100%;line-height: 1.5em;font-size: 16px;color: #101112;text-align: center;margin: 10px 0;font-weight: bold;}
  .success_info{ width: 100%;font-size: 14px;color: #8993A8;text-align: center;margin: 10px 0 35px 0;}
</style>
